/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

$walley-primary: mat.m2-define-palette(mat.$m2-blue-palette, A700, A200, A400);
$walley-accent: mat.m2-define-palette(mat.$m2-pink-palette, 500, 700, 600);

// The warn palette is optional (defaults to red).
$walley-warn: mat.m2-define-palette(mat.$m2-red-palette, A700, A400, A200);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$walley-theme: mat.m2-define-light-theme((color: (primary: $walley-primary,
        accent: $walley-accent,
        warn: $walley-warn,
      ),
      density: -1));

// @include mat.core-theme($walley-theme);
// @include mat.button-theme($walley-theme);
@include mat.all-component-themes($walley-theme);

/* START Colori */
$bluescuro_logo: rgb(0, 45, 93);
$bluechiaro_logo: rgb(0, 154, 222);

//per modificare le variabili generali di bootstrap
$enable-gradients: true;

.bg-theme_logoscuro {
  background-color: $bluescuro_logo !important;
}

.bg-theme_logochiaro {
  background-color: $bluechiaro_logo;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

//usata in app.component.html
.altezza_home {
  min-height: 100%;
  //height: 85%;
}

.disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
  // background: #CCC;
}

//pulire pulsanti e <a>
.a_clean {
  text-decoration: none !important;
  background-color: inherit;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;

  }
}

// usato perchè altrimenti il component dell'ora compariva sopra il dialog
.cdk-overlay-container {
  z-index: 500 !important;
}

// sono gli alert pop-up
.modal_popup .mat-dialog-container {
  max-width: 600px;
}

// usato per togliere il background a input material fill
.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

// usato per i dialog. Per farli adattare
.dialog-custom-responsive {
  width: 100%;
}

@media (min-width: 992px) {
  .dialog-custom-responsive {
    width: 80%;
  }
}

@media (min-width: 1400px) {
  .dialog-custom-responsive {
    width: 60%;
  }
}

/* START usato per modal image a full screen */
.custom_covermodal_image {
  width: 300px;
  max-width: 100%;
}
@media (min-width: 576px) {
  .custom_covermodal_image {
    width: 600px;
    max-width: 90%;
    max-height: 80%;
  }
  .custom_covermodal_image mat-dialog-content{
    max-height: 90vh;
  }
}
@media (min-width: 992px) {
  .custom_covermodal_image {
    width: 80%;
  }
  .custom_covermodal_image mat-dialog-content img{
    max-height: none;
  }
}
@media (min-width: 1492px) {
  .custom_covermodal_image {
    max-width: 1400px !important;
  }
}
/* END usato per modal image a full screen */
